import { EntityLayout, EntityHasSystemsCard } from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import {
  isRoadmapAnnotationAvailable,
  TechdocRoadmapsAndGoldenpathViewerPage,
} from '@efg/plugin-techdoc-goldenpath-and-roadmaps';
import { Grid } from '@material-ui/core';
import React from 'react';
import { AboutCard as EntityAboutCard } from '../../pages/AboutCard';
import { entityWarningContent } from '../EntityContent/EntityWarningContent';

export const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isRoadmapAnnotationAvailable}
      path="/roadmap"
      title="Roadmap"
    >
      <TechdocRoadmapsAndGoldenpathViewerPage />
    </EntityLayout.Route>
  </EntityLayout>
);
