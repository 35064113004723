import {
  EntityLayout,
  EntityHasSubcomponentsCard,
} from '@backstage/plugin-catalog';
import { EntityCatalogGraphCard } from '@backstage/plugin-catalog-graph';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  isRoadmapAnnotationAvailable,
  TechdocRoadmapsAndGoldenpathViewerPage,
} from '@efg/plugin-techdoc-goldenpath-and-roadmaps';
import {
  isGitlabAvailable,
  EntityGitlabContent,
} from '@immobiliarelabs/backstage-plugin-gitlab';
import { Grid } from '@material-ui/core';
import React from 'react';
import { CicdContent } from '../../../EntityContent/CicdContent';
import { overviewContent } from '../../../EntityContent/DefaultOverviewContent';
import { techdocsContent } from '../../../EntityContent/TechdocsContent';

export const DocumentationEntityPage = () => {
  const { entity } = useEntity();

  const hasSubcomponents = entity?.relations?.some(
    relation => relation.type === 'hasPart',
  );

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/ci-cd" title="CI/CD">
        <CicdContent />
      </EntityLayout.Route>

      <EntityLayout.Route
        if={isGitlabAvailable}
        path="/repo-info"
        title="Repo Info"
      >
        <EntityGitlabContent />
      </EntityLayout.Route>

      <EntityLayout.Route
        if={isRoadmapAnnotationAvailable}
        path="/roadmap"
        title="Roadmap"
      >
        <TechdocRoadmapsAndGoldenpathViewerPage />
      </EntityLayout.Route>
      <EntityLayout.Route path="/relations" title="Relations">
        <>
          <Grid item md={12} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={300} />
          </Grid>
          {hasSubcomponents && (
            <Grid item md={8} xs={12}>
              <EntityHasSubcomponentsCard variant="gridItem" />
            </Grid>
          )}
        </>
      </EntityLayout.Route>
    </EntityLayout>
  );
};
