import {
  genPageTheme,
  shapes,
  themes,
  createUnifiedTheme,
  UnifiedTheme,
  createBaseThemeOptions,
  defaultTypography,
  BackstageTypography,
} from '@backstage/theme';
import { Palette, PaletteOptions } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles';

// https://brand.eslfaceitgroup.com/d/FVsWbUCMAt79/efg?#/core-components/colors
// const efg_primary_colors = {
//   "Black": "#000000",
//   "White":  "#000000",
// }
//
// const efg_secondary_colors = {
//   // secondary dark
//   "Cod Grey": "#141414",
//   "Mine Shaft":  "#262626",
//   "Boulder": "#4e4e4e",
//
//   // primary (ligth)
//   "Silver Chalice": "#c3c3c3",
//   "Silver": "#c3c3c3",
//   "Mercury": "#e7e7e7",
// }

export const darkEFGPalette: PaletteOptions = {
  ...themes.dark.getTheme('v5')?.palette,
  primary: {
    main: '#e7e7e7',
    dark: '#9e9e9e',
    light: '#c3c3c3',
    contrastText: '#4e4e4e',
  },
  secondary: {
    main: '#9e9e9e',
    dark: '#e7e7e7',
    light: '#c3c3c3',
    contrastText: '#e7e7e7',
  },
  background: {
    default: '#141414',
    paper: '#262626',
  },
  // @ts-ignore
  banner: {
    ...themes.dark.getTheme('v5')?.palette.banner,
    link: '#fff',
    text: '#e7e7e7',
  },
  // @ts-ignore
  navigation: {
    ...themes.dark.getTheme('v5')?.palette.navigation,
    submenu: {
      background: '#4e4e4e',
    },
  },
};

export const lightEFGPalette: PaletteOptions = {
  ...themes.light.getTheme('v5')?.palette,
  primary: {
    main: '#262626',
    dark: '#4e4e4e',
    light: '#4e4e4e',
    contrastText: '#e7e7e7',
  },
  secondary: {
    main: '#141414',
    dark: '#262626',
    light: '#262626',
    contrastText: '#e7e7e7',
  },
  background: {
    default: '#FFF',
    paper: '#e7e7e7',
  },
  // @ts-ignore
  banner: {
    ...themes.dark.getTheme('v5')?.palette.banner,
    link: '#fff',
    text: '#e7e7e7',
  },
  // @ts-ignore
  navigation: {
    ...themes.light.getTheme('v5')?.palette.navigation,
    submenu: {
      background: '#4e4e4e',
    },
  },
};

const createComponentOverrides = (
  palette: PaletteOptions,
): ThemeOptions['components'] => {
  return {
    BackstageSidebar: {
      styleOverrides: {
        drawer: {
          background: '#262626',
        },
      },
    },
    BackstageStatus: {
      styleOverrides: {
        status: {
          fontSize: '0.875rem',
        },
      },
    },
    // @ts-ignore
    BackstageSidebarSubmenuItem: {
      styleOverrides: {
        label: {
          color: '#e7e7e7',
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          '& span': {
            fontSize: 15,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          lineHeight: 2,
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          lineHeight: '2!important',
          // @ts-ignore
          color: palette?.secondary?.main,
        },
      },
    },
  };
};

const buildTypographyOptions = (): BackstageTypography => {
  return {
    ...defaultTypography,
    fontFamily: 'Barlow',
    h6: {
      ...defaultTypography.h6,
      fontFamily: 'Barlow Condensed',
      fontWeight: 500,
    },
    h5: {
      ...defaultTypography.h5,
      fontFamily: 'Barlow Condensed',
      fontWeight: 500,
    },
    h4: {
      ...defaultTypography.h4,
      fontFamily: 'Barlow Condensed',
      fontWeight: 500,
    },
    h3: {
      ...defaultTypography.h3,
      fontFamily: 'Barlow Condensed',
    },
    h2: {
      ...defaultTypography.h2,
      fontFamily: 'Barlow Condensed',
    },
    h1: {
      ...defaultTypography.h1,
      fontFamily: 'Barlow Condensed',
    },
  };
};

export const darkEFGTheme: UnifiedTheme = createUnifiedTheme({
  defaultPageTheme: 'home',
  fontFamily: 'Barlow',
  pageTheme: {
    home: genPageTheme({ colors: ['#141414', '#262626'], shape: shapes.wave }),
  },
  components: {
    ...createComponentOverrides(darkEFGPalette as Palette),
  },
  ...createBaseThemeOptions({
    palette: darkEFGPalette,
    typography: {
      ...defaultTypography,
      ...buildTypographyOptions(),
    },
  }),
});

export const lightEFGTheme: UnifiedTheme = createUnifiedTheme({
  defaultPageTheme: 'home',
  fontFamily: 'Barlow',
  pageTheme: {
    home: genPageTheme({ colors: ['#141414', '#262626'], shape: shapes.wave }),
  },
  components: {
    ...createComponentOverrides(lightEFGPalette as Palette),
  },
  ...createBaseThemeOptions({
    palette: lightEFGPalette,
    typography: {
      ...defaultTypography,
      ...buildTypographyOptions(),
    },
  }),
});
