import React from 'react';
import { EntitySwitch, isKind } from '@backstage/plugin-catalog';
import { defaultEntityPage } from './EntityKind/ComponentPage/ComponentPageTypes/DefaultEntityPage';
import { domainPage } from './EntityKind/DomainPage';
import { systemPage } from './EntityKind/SystemPage';
import { userPage } from './EntityKind/UserPage';
import { GroupPage } from './EntityKind/GroupPage';
import { apiPage } from './EntityKind/ApiPage';
import { componentPage } from './EntityKind/ComponentPage/ComponentPage';

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')}>
      <GroupPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />
    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
