import React from 'react';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityLayout,
} from '@backstage/plugin-catalog';
import {
  isGitlabAvailable,
  EntityGitlabContent,
} from '@immobiliarelabs/backstage-plugin-gitlab';
import {
  BackstagePluginKubecostPage,
  isKubecostAvailable,
} from '@suxess-it/backstage-plugin-kubecost';
import { Grid } from '@material-ui/core';
import {
  TechdocRoadmapsAndGoldenpathViewerPage,
  isRoadmapAnnotationAvailable,
} from '@efg/plugin-techdoc-goldenpath-and-roadmaps';
import {
  OpaPolicyPage,
  isOpaPoliciesEnabled,
} from '@parsifal-m/plugin-opa-policies';
import { overviewContent } from '../../../EntityContent/DefaultOverviewContent';
import { techdocsContent } from '../../../EntityContent/TechdocsContent';
import { CicdContent } from '../../../EntityContent/CicdContent';

export const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      <CicdContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isGitlabAvailable}
      path="/repo-info"
      title="Repo Info"
    >
      <EntityGitlabContent />
    </EntityLayout.Route>

    <EntityLayout.Route if={isKubecostAvailable} path="/kubecost" title="Cost">
      <BackstagePluginKubecostPage />
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isOpaPoliciesEnabled}
      path="/opa"
      title="Open Policy Agent"
    >
      <OpaPolicyPage />
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isRoadmapAnnotationAvailable}
      path="/roadmap"
      title="Roadmap"
    >
      <TechdocRoadmapsAndGoldenpathViewerPage />
    </EntityLayout.Route>
  </EntityLayout>
);
