import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import { Routes, Route } from 'react-router-dom';
import { EntityNavDropdown } from './EntityNavDropdown';

type Props = {
  path: string;
  title: string;
  content: React.JSX.Element;
};
export const entityRoutes = (routes: Props[]) => {
  return (
    <EntityLayout.Route
      path=""
      title=""
      tabProps={{
        component: EntityNavDropdown,
        routes: routes.map((item: Props) => ({
          path: item.path,
          title: item.title,
        })),
      }}
    >
      <Routes>
        {routes.map((item: Props) => (
          <Route key={item.path} path={item.path} element={item.content} />
        ))}
      </Routes>
    </EntityLayout.Route>
  );
};
