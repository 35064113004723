import {
  createApiRef,
  DiscoveryApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import {
  ListVersionsRequestJson,
  VersionJson,
} from '@faceit/protos-dts/animals/api/v1alpha1/versions_pb';

export const animalsApiRef = createApiRef<AnimalsApi>({
  id: 'plugin.animals.service',
});

export interface Timestamp {
  seconds: number;
  nanos?: number;
}

export interface DeployDetails {
  message: string;
  url?: string;
}

export interface Deploy {
  id: string;
  jobId: string;
  type: number;
  service: string;
  component: string;
  environment: string;
  teamTag: string;
  status: number;
  kind: number;
  version: string;
  email: string;
  createdAt: Timestamp;
  details?: DeployDetails;
}

export interface Release {
  name: string;
  url: string;
  publishedAt: Timestamp;
}

export interface Tag {
  tag: string;
  createdAt: Timestamp;
  release?: Release;
}

export interface ListDeploysOptions {
  component?: string;
  environment?: string;
  limit?: string;
}
export interface CreateDeployOptions {
  component?: string;
  environment?: string;
  service?: string;
  version?: string;
  author?: string;
}
export interface ListReleasesOptions {
  component?: string;
  environment?: string;
  service?: string;
  author?: string;
  limit?: number;
}
export interface ListContainerTagsOptions {
  component?: string;
  environment?: string;
  service?: string;
  author?: string;
  limit?: number;
}
export interface ListEnvironmentsOptions {
  name?: string;
}
export interface AnimalsApi {
  /**
   * List the latest deployments triggered by using animals
   *
   */
  listDeploys(options?: ListDeploysOptions): Promise<Deploy[]>;
  createDeploy(options?: CreateDeployOptions): Promise<Deploy[]>;
  listReleases(options?: ListReleasesOptions): Promise<Release[]>;
  listContainerTags(options?: ListContainerTagsOptions): Promise<Tag[]>;
  listEnvironments(options?: ListEnvironmentsOptions): Promise<string[]>;
  listVersions(options?: ListVersionsRequestJson): Promise<VersionJson[]>; // Note this is a work around the difference serialization between protos-commonjs and protos-ts (we need to revisit)
}

export class AnimalsClient implements AnimalsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    identityApi: IdentityApi;
  }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  /**
   * Handles errors when talking to the backend plugin
   *
   */
  private async handleResponse(response: Response): Promise<any> {
    if (!response.ok) {
      const payload = await response.text();
      let message;
      switch (response.status) {
        case 404:
          message =
            'Could not find the Animals Backend (HTTP 404). Make sure the plugin has been fully installed.';
          break;
        default:
          message = `Request failed with ${response.status} ${response.statusText}, ${payload}`;
      }
      throw new Error(message);
    }

    return await response.json();
  }

  async listDeploys(options?: ListDeploysOptions): Promise<Deploy[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl('animals')}/deploys`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
        'Content-Type': 'application/json',
      },
    });

    return await this.handleResponse(response);
  }
  async createDeploy(options?: CreateDeployOptions): Promise<Deploy[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl('animals')}/deploy`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
        'Content-Type': 'application/json',
      },
    });

    return await this.handleResponse(response);
  }
  async listReleases(options?: ListReleasesOptions): Promise<Release[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl('animals')}/releases`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
        'Content-Type': 'application/json',
      },
    });

    return await this.handleResponse(response);
  }
  async listContainerTags(options?: ListContainerTagsOptions): Promise<Tag[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl(
      'animals',
    )}/containertags`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
        'Content-Type': 'application/json',
      },
    });

    return await this.handleResponse(response);
  }
  async listEnvironments(options?: ListEnvironmentsOptions): Promise<string[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl('animals')}/environments`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
        'Content-Type': 'application/json',
      },
    });

    return await this.handleResponse(response);
  }

  async listVersions(
    options?: ListVersionsRequestJson,
  ): Promise<VersionJson[]> {
    const { token: idToken } = await this.identityApi.getCredentials();
    const url = `${await this.discoveryApi.getBaseUrl('animals')}/versions`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(options),
      headers: {
        ...(idToken && { Authorization: `Bearer ${idToken}` }),
        'Content-Type': 'application/json',
      },
    });

    return await this.handleResponse(response);
  }
}
