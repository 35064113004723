import React, { useRef, useState } from 'react';
import { Menu, MenuItem, Button } from '@material-ui/core';
import { Link, useResolvedPath, useLocation } from 'react-router-dom';
import { useTheme, makeStyles, createStyles } from '@material-ui/core';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { lightEFGPalette, darkEFGPalette } from '../../../theme/efg';

type Route = {
  path: string;
  title: string;
};

export const EntityNavDropdown = ({ routes }: { routes: Route[] }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const resolvedPath = useResolvedPath('');
  const location = useLocation();
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const activeButtonThemeColor =
    theme.palette.type === 'light'
      ? theme.palette.common.black
      : theme.palette.common.white;
  const hoverButtonThemeColor =
    theme.palette.type === 'light'
      ? lightEFGPalette.background?.default
      : darkEFGPalette.background?.default;
  const buttonThemeColor =
    theme.palette.type === 'light'
      ? theme.palette.grey[600]
      : theme.palette.grey[500];
  const isRouteSelected = routes.some(
    route => location.pathname === `${resolvedPath.pathname}${route.path}`,
  );
  const buttonRef = useRef(null);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    const target = event.currentTarget;
    const targetRect = target.getBoundingClientRect();
    const mouseX = event.clientX;
    const mouseY = event.clientY;
    if (buttonRef.current === target) {
      if (
        mouseX < targetRect.left ||
        mouseX > targetRect.right ||
        mouseY < targetRect.top
      ) {
        setAnchorEl(null);
      }
    } else {
      setAnchorEl(null);
    }
  };

  const useStyles = makeStyles(() =>
    createStyles({
      button: {
        color: isRouteSelected ? activeButtonThemeColor : buttonThemeColor,
        minWidth: 'auto',
        fontSize: '12px',
        fontWeight: 700,
        padding: '0 24px',
        borderRadius: 'initial',
        opacity: 0.8,
        letterSpacing: '0.07em',

        '&:hover': {
          backgroundColor: hoverButtonThemeColor,
        },
        backgroundColor: open ? hoverButtonThemeColor : '',
      },
      menu: {
        maxWidth: '500px',
        '& > div': {
          maxWidth: '500px',
        },
      },
      menuItem: {
        color: isRouteSelected ? activeButtonThemeColor : buttonThemeColor,
        minWidth: '135px',
      },
    }),
  );

  const classes = useStyles();

  return (
    <>
      <Button
        ref={buttonRef}
        className={classes.button}
        id="menu-button"
        aria-haspopup="true"
        aria-controls={open ? 'menu-dropdown' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        endIcon={<KeyboardArrowDownIcon />}
      >
        More Info
      </Button>
      <Menu
        className={classes.menu}
        id="menu-dropdown"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        {routes.map(route => (
          <MenuItem
            className={classes.menuItem}
            key={route.path}
            component={Link}
            to={`${resolvedPath.pathname}${route.path}`}
            onClick={handleClose}
            style={{
              textDecoration:
                location.pathname === `${resolvedPath.pathname}${route.path}`
                  ? 'underline'
                  : 'none',
              color:
                location.pathname === `${resolvedPath.pathname}${route.path}`
                  ? activeButtonThemeColor
                  : buttonThemeColor,
            }}
          >
            {route.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
