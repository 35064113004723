import { useEntity } from '@backstage/plugin-catalog-react';
import LanguageIcon from '@material-ui/icons/Language';
import React from 'react';
import { Grid } from '@material-ui/core';
import { IconComponent, useApp } from '@backstage/core-plugin-api';
import { HomePageToolkit } from '@backstage/plugin-home';
import { useTheme, makeStyles, createStyles } from '@material-ui/core/styles';
import LinkIcon from '@mui/icons-material/Link';
import { lightEFGPalette, darkEFGPalette } from '../../../theme/efg';

export const EntityLinks = () => {
  const { entity } = useEntity();
  const app = useApp();
  const links = entity?.metadata?.links;
  const iconResolver = (key?: string): IconComponent =>
    key ? (app.getSystemIcon(key) ?? LanguageIcon) : LanguageIcon;
  const theme = useTheme();
  const themedBackground =
    theme.palette.type === 'light'
      ? lightEFGPalette.background?.default
      : darkEFGPalette.background?.default;
  const useStyles = makeStyles(() =>
    createStyles({
      linkContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
        '& a > *:first-child': {
          width: '54px',
          height: '54px',
        },
        '& a:hover::after': {
          opacity: 1,
          transition: 'opacity 100ms 900ms',
        },
        '& a:after': {
          content: 'attr(href)',
          fontSize: '0.8em',
          display: 'block',
          position: 'absolute',
          zIndex: 9999,
          pointerEvents: 'none',
          backgroundColor: themedBackground,
          padding: '0.3em 0.6em',
          borderRadius: '0.2em',
          opacity: 0,
          left: '50%',
          transform: 'translateX(-50%)',
        },

        '& > div': {
          overflow: 'visible',
          width: '100%',
          '& > div': {
            padding: '4px',
          },
          '& > *:first-child': {
            display: 'none',
          },
          '& hr': {
            display: 'none',
          },
        },
      },
    }),
  );
  const classes = useStyles();
  if (!links || links.length === 0) {
    return null;
  }
  const tools =
    links?.map(({ url, title, icon }) => ({
      url,
      label: title || url,
      icon: (
        <>{icon ? React.createElement(iconResolver(icon)) : <LinkIcon />}</>
      ),
    })) || [];

  return (
    <Grid className={classes.linkContainer} container item xs={12}>
      <HomePageToolkit tools={tools} title=" " />
    </Grid>
  );
};
