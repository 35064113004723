import { EntityLayout } from '@backstage/plugin-catalog';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { EntityOwnershipCard } from '@backstage/plugin-org';
import { isTechDocsAvailable } from '@backstage/plugin-techdocs';
import {
  EntityGroupProfileWithManager,
  EntityMembersListWithManager,
} from '@efg/backstage-plugin-org-with-manager';
import { Grid } from '@material-ui/core';
import React from 'react';
import { entityWarningContent } from '../EntityContent/EntityWarningContent';
import { techdocsContent } from '../EntityContent/TechdocsContent';

export const GroupPage = () => {
  const { entity: groupEntity } = useAsyncEntity();
  const managerTitle = (() => {
    switch (groupEntity?.spec?.type) {
      case 'team':
        return 'Engineering Manager';
      case 'department':
        return 'Department Head';
      case 'division':
        return 'CTO';
      default:
        return 'Manager';
    }
  })();

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          {entityWarningContent}
          <Grid item xs={12} md={12}>
            <EntityGroupProfileWithManager
              variant="gridItem"
              managerTitle={managerTitle}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntityOwnershipCard variant="gridItem" entityLimit={9} />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntityMembersListWithManager managerTitle={managerTitle} />
          </Grid>
        </Grid>
      </EntityLayout.Route>
      <EntityLayout.Route if={isTechDocsAvailable} path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>
    </EntityLayout>
  );
};
