import React from 'react';
import {
  HomePageCompanyLogo,
  HomePageStarredEntities,
  CustomHomepageGrid,
  HomePageTopVisited,
  HomePageRecentlyVisited,
} from '@backstage/plugin-home';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { Grid, makeStyles, useTheme } from '@material-ui/core';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { HomePageIncidentCard } from '@efg/incident-io-backstage';
import { AnnouncementsCard } from '@procore-oss/backstage-plugin-announcements';
import { HomePageSearchBarExtension } from './customizable/HomePageSearchBarExtension';
import { EFGLogoExtension } from './customizable/EFGLogoExtension';
import {
  BackstageDocs,
  backstageDocsContent,
} from './customizable/BackstageDocs';
import { usefulLinksContent, UsefulLinks } from './customizable/UsefulLinks';

const useStyles = makeStyles(theme => ({
  searchBar: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    margin: 'auto',
  },
}));

const useLogoStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(5, 0),
  },
  logo: {
    width: 'auto',
    height: 200,
    fill: theme.palette.text.primary,
  },
}));
const customizableLogoStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    height: '200px',
    justifyContent: 'center',
  },
  logo: {
    height: '100%',
  },
}));

export const EFGLogo = () => {
  const { logo, container } = useLogoStyles();

  const themeLogo =
    useTheme().palette.type === 'light'
      ? '/EFG_Logo_Black_RGB.svg'
      : '/EFG_Logo_White_RGB.svg';

  return (
    <HomePageCompanyLogo
      className={container}
      logo={<img className={logo} src={themeLogo} alt="EFG Logo" />}
    />
  );
};

export const OldHomePage = () => {
  const configApi = useApi(configApiRef);
  const baseUrl = configApi.getString('app.baseUrl');
  const classes = useStyles();
  const backstageDocsProps = backstageDocsContent(baseUrl);
  const usefulLinksProps = usefulLinksContent(baseUrl);

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container direction="column">
            <Grid
              container
              item
              justifyContent="center"
              style={{ marginBottom: '50px' }}
            >
              <EFGLogo />
              <Grid container item xs={12} alignItems="center">
                <HomePageSearchBar
                  classes={{ root: classes.searchBar }}
                  placeholder="Search"
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} direction="row">
              <Grid container item xs={12} lg={6} alignItems="stretch">
                <Grid item xs={12}>
                  <HomePageStarredEntities />
                </Grid>
                <Grid item xs={12}>
                  <HomePageIncidentCard />
                </Grid>
              </Grid>

              <Grid container item xs={12} lg={6}>
                <Grid item xs={12} style={{ margin: '0 0 10px 0' }}>
                  <UsefulLinks
                    tools={usefulLinksProps.tools}
                    title={usefulLinksProps.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BackstageDocs
                    tools={backstageDocsProps.tools}
                    title={backstageDocsProps.title}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};

const defaultConfig = [
  {
    component: <EFGLogoExtension />,
    x: 5,
    y: 0,
    width: 12,
    height: 3,
    movable: true,
    resizable: true,
    deletable: true,
  },
  {
    component: <HomePageSearchBarExtension />,
    x: 1,
    y: 4,
    height: 2,
    width: 10,
  },
  {
    component: <BackstageDocs />,
    x: 0,
    y: 5,
    height: 5,
    width: 4,
  },
  {
    component: <HomePageStarredEntities />,
    x: 4,
    y: 5,
    height: 5,
    width: 4,
  },
  {
    component: <HomePageTopVisited />,
    x: 8,
    y: 5,
    height: 5,
    width: 4,
  },
  {
    component: <HomePageIncidentCard />,
    x: 0,
    y: 10,
    height: 6,
    width: 6,
  },
  {
    component: <AnnouncementsCard />,
    x: 6,
    y: 10,
    height: 6,
    width: 6,
  },
];

export const HomePage = () => {
  const { logo, container } = customizableLogoStyles();
  const themeLogo =
    useTheme().palette.type === 'light'
      ? '/EFG_Logo_Black_RGB.svg'
      : '/EFG_Logo_White_RGB.svg';
  const configApi = useApi(configApiRef);
  const baseUrl = configApi.getString('app.baseUrl');
  const backstageDocsProps = backstageDocsContent(baseUrl);
  const usefulLinksProps = usefulLinksContent(baseUrl);
  return (
    <CustomHomepageGrid config={defaultConfig}>
      <EFGLogoExtension
        className={container}
        logo={<img src={themeLogo} alt="EFG Logo" className={logo} />}
      />
      <HomePageSearchBarExtension placeholder="Search" />
      <UsefulLinks
        tools={usefulLinksProps.tools}
        title={usefulLinksProps.title}
      />
      <BackstageDocs
        tools={backstageDocsProps.tools}
        title={backstageDocsProps.title}
      />
      <HomePageStarredEntities />
      <HomePageIncidentCard />
      <HomePageTopVisited />
      <HomePageRecentlyVisited />
      <AnnouncementsCard variant="fullHeight" />
    </CustomHomepageGrid>
  );
};
