import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
} from '@backstage-community/plugin-github-actions';
import {
  isJenkinsAvailable,
  EntityJenkinsContent,
} from '@backstage-community/plugin-jenkins';
import { EmptyState } from '@backstage/core-components';
import { EntitySwitch } from '@backstage/plugin-catalog';
import {
  isGitlabAvailable,
  EntityGitlabPipelinesTable,
} from '@immobiliarelabs/backstage-plugin-gitlab';
import { Button } from '@material-ui/core';
import React from 'react';

export const CicdContent = () => {
  return (
    <EntitySwitch>
      <EntitySwitch.Case if={isGithubActionsAvailable}>
        <EntityGithubActionsContent />
      </EntitySwitch.Case>
      <EntitySwitch.Case if={isJenkinsAvailable}>
        <EntityJenkinsContent />
      </EntitySwitch.Case>
      <EntitySwitch.Case if={isGitlabAvailable}>
        <EntityGitlabPipelinesTable />
      </EntitySwitch.Case>
      <EntitySwitch.Case>
        <EmptyState
          title="No CI/CD available for this entity"
          missing="info"
          description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
          action={
            <Button
              variant="contained"
              color="primary"
              href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
            >
              Read more
            </Button>
          }
        />
      </EntitySwitch.Case>
    </EntitySwitch>
  );
};
